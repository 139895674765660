<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column">
        <p class="title">Customize Issues</p>
      </div>
    </div>
    <hr />
    <div class="columns">
      <div class="column">
        <form @submit.prevent="submitForm" id="editorForm">
          <div class="box">
            <div class="columns">
              <div class="column">
                <div v-if="!updatemagazine1">
                  <div>
                    <div class="columns mt-1">
                      <div class="column is-narrow pt-1">
                        <div class="bold">Issue File</div>
                      </div>
                      <div class="column has-text-right pt-1">
                        <a @click="updateResource(1, 1)">
                          <b-tag type="is-primary is-light">
                            <i class="mdi mdi-pencil pr-1"></i> Update File
                          </b-tag>
                        </a>
                      </div>
                    </div>
                    Issue already uploaded.
                    <a :href="formData.magazine1"> Click here to download. </a>
                  </div>
                </div>
                <div v-if="updatemagazine1">
                  <b-field label="Issue File">
                    <section>
                      <b-field>
                        <b-upload v-model="magazine1File" multiple drag-drop>
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon icon="upload" size="is-large"> </b-icon>
                              </p>
                              <p>Drop your files here or click to upload</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <div class="tags">
                        <span
                          v-for="(file, index) in magazine1File"
                          :key="index"
                          class="tag is-primary"
                        >
                          {{ file.name }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index, 1)"
                          ></button>
                        </span>
                      </div>
                    </section>
                  </b-field>
                </div>
              </div>
              <div class="column">
                <b-field label="Title*">
                  <b-input
                    v-model="formData.title1"
                    required
                    disabled
                  ></b-input>
                </b-field>
                <b-field v-if="false" label="Subtitle*">
                  <b-input v-model="formData.subtitle1" required></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <div v-if="!updatemagazine2">
                  <div>
                    <div class="columns mt-1">
                      <div class="column is-narrow pt-1">
                        <div class="bold">Issue File</div>
                      </div>
                      <div class="column has-text-right pt-1">
                        <a @click="updateResource(2, 1)">
                          <b-tag type="is-primary is-light">
                            <i class="mdi mdi-pencil pr-1"></i> Update File
                          </b-tag>
                        </a>
                      </div>
                    </div>
                    Issue already uploaded.
                    <a :href="formData.magazine2"> Click here to download. </a>
                  </div>
                </div>
                <div v-if="updatemagazine2">
                  <b-field label="Issue File">
                    <section>
                      <b-field>
                        <b-upload v-model="magazine2File" multiple drag-drop>
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon icon="upload" size="is-large"> </b-icon>
                              </p>
                              <p>Drop your files here or click to upload</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <div class="tags">
                        <span
                          v-for="(file, index) in magazine2File"
                          :key="index"
                          class="tag is-primary"
                        >
                          {{ file.name }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index, 2)"
                          ></button>
                        </span>
                      </div>
                    </section>
                  </b-field>
                </div>
              </div>
              <div class="column">
                <b-field label="Title*">
                  <b-input
                    v-model="formData.title2"
                    required
                    disabled
                  ></b-input>
                </b-field>
                <b-field v-if="false" label="Subtitle*">
                  <b-input v-model="formData.subtitle2" required></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <div v-if="!updatemagazine3">
                  <div>
                    <div class="columns mt-1">
                      <div class="column is-narrow pt-1">
                        <div class="bold">Issue File</div>
                      </div>
                      <div class="column has-text-right pt-1">
                        <a @click="updateResource(3, 1)">
                          <b-tag type="is-primary is-light">
                            <i class="mdi mdi-pencil pr-1"></i> Update File
                          </b-tag>
                        </a>
                      </div>
                    </div>
                    Issue already uploaded.
                    <a :href="formData.magazine3"> Click here to download. </a>
                  </div>
                </div>
                <div v-if="updatemagazine3">
                  <b-field label="Issue File">
                    <section>
                      <b-field>
                        <b-upload v-model="magazine3File" multiple drag-drop>
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon icon="upload" size="is-large"> </b-icon>
                              </p>
                              <p>Drop your files here or click to upload</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <div class="tags">
                        <span
                          v-for="(file, index) in magazine3File"
                          :key="index"
                          class="tag is-primary"
                        >
                          {{ file.name }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index, 3)"
                          ></button>
                        </span>
                      </div>
                    </section>
                  </b-field>
                </div>
              </div>
              <div class="column">
                <b-field label="Title*">
                  <b-input
                    v-model="formData.title3"
                    required
                    disabled
                  ></b-input>
                </b-field>
                <b-field v-if="false" label="Subtitle*">
                  <b-input v-model="formData.subtitle3" required></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div class="box">
            <div class="columns">
              <div class="column">
                <div v-if="!updatemagazine4">
                  <div>
                    <div class="columns mt-1">
                      <div class="column is-narrow pt-1">
                        <div class="bold">Issue File</div>
                      </div>
                      <div class="column has-text-right pt-1">
                        <a @click="updateResource(4, 1)">
                          <b-tag type="is-primary is-light">
                            <i class="mdi mdi-pencil pr-1"></i> Update File
                          </b-tag>
                        </a>
                      </div>
                    </div>
                    Issue already uploaded.
                    <a :href="formData.magazine4"> Click here to download. </a>
                  </div>
                </div>
                <div v-if="updatemagazine4">
                  <b-field label="Issue File">
                    <section>
                      <b-field>
                        <b-upload v-model="magazine4File" multiple drag-drop>
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon icon="upload" size="is-large"> </b-icon>
                              </p>
                              <p>Drop your files here or click to upload</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <div class="tags">
                        <span
                          v-for="(file, index) in magazine4File"
                          :key="index"
                          class="tag is-primary"
                        >
                          {{ file.name }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index, 4)"
                          ></button>
                        </span>
                      </div>
                    </section>
                  </b-field>
                </div>
              </div>
              <div class="column">
                <b-field label="Title*">
                  <b-input
                    v-model="formData.title4"
                    required
                    disabled
                  ></b-input>
                </b-field>
                <b-field v-if="false" label="Subtitle*">
                  <b-input v-model="formData.subtitle4" required></b-input>
                </b-field>
              </div>
            </div>
          </div>

          <div v-if="false" class="box">
            <div class="columns">
              <div class="column">
                <div v-if="!updatemagazine5">
                  <div>
                    <div class="columns mt-1">
                      <div class="column is-narrow pt-1">
                        <div class="bold">Issue File</div>
                      </div>
                      <div class="column has-text-right pt-1">
                        <a @click="updateResource(5, 1)">
                          <b-tag type="is-primary is-light">
                            <i class="mdi mdi-pencil pr-1"></i> Update File
                          </b-tag>
                        </a>
                      </div>
                    </div>
                    Issue already uploaded.
                    <a :href="formData.magazine5"> Click here to download. </a>
                  </div>
                </div>
                <div v-if="updatemagazine5">
                  <b-field label="Issue File">
                    <section>
                      <b-field>
                        <b-upload v-model="magazine5File" multiple drag-drop>
                          <section class="section">
                            <div class="content has-text-centered">
                              <p>
                                <b-icon icon="upload" size="is-large"> </b-icon>
                              </p>
                              <p>Drop your files here or click to upload</p>
                            </div>
                          </section>
                        </b-upload>
                      </b-field>

                      <div class="tags">
                        <span
                          v-for="(file, index) in magazine5File"
                          :key="index"
                          class="tag is-primary"
                        >
                          {{ file.name }}
                          <button
                            class="delete is-small"
                            type="button"
                            @click="deleteDropFile(index, 5)"
                          ></button>
                        </span>
                      </div>
                    </section>
                  </b-field>
                </div>
              </div>
              <div class="column">
                <b-field label="Title*">
                  <b-input v-model="formData.title5" required></b-input>
                </b-field>
                <b-field label="Subtitle*">
                  <b-input v-model="formData.subtitle5" required></b-input>
                </b-field>
              </div>
            </div>
          </div>
          <hr />
          <div class="has-text-right">
            <!-- <b-button @click="cancel" class="button">Cancel</b-button> -->
            <button
              form="editorForm"
              class="button is-primary ml-4"
              type="submit"
              :disabled="isSubmitting"
            >
              {{ isSubmitting ? "Saving" : "Save" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
export default {
  name: "Editor",
  data() {
    return {
      isSubmitting: false,
      magazineItems: undefined,

      formData: {
        magazine1: undefined,
        title1: undefined,
        subtitle1: undefined,
        magazine2: undefined,
        title2: undefined,
        subtitle2: undefined,
        magazine3: undefined,
        title3: undefined,
        subtitle3: undefined,
        magazine4: undefined,
        title4: undefined,
        subtitle4: undefined,
        magazine5: undefined,
        title5: undefined,
        subtitle5: undefined
      },

      updatemagazine1: undefined,
      updatemagazine2: undefined,
      updatemagazine3: undefined,
      updatemagazine4: undefined,
      updatemagazine5: undefined,

      magazine1File: [],
      magazine2File: [],
      magazine3File: [],
      magazine4File: [],
      magazine5File: []
    };
  },
  async created() {
    await this.$store.dispatch("getMagazineObjects");
    this.magazineItems = this.items;
    this.magazineItems.forEach((item) => {
      if (item.magazineNo == 1) {
        this.formData.magazine1 = item.attachmentURL;
        this.formData.title1 = item.title;
        this.formData.subtitle1 = item.subtitle;
      } else if (item.magazineNo == 2) {
        this.formData.magazine2 = item.attachmentURL;
        this.formData.title2 = item.title;
        this.formData.subtitle2 = item.subtitle;
      } else if (item.magazineNo == 3) {
        this.formData.magazine3 = item.attachmentURL;
        this.formData.title3 = item.title;
        this.formData.subtitle3 = item.subtitle;
      } else if (item.magazineNo == 4) {
        this.formData.magazine4 = item.attachmentURL;
        this.formData.title4 = item.title;
        this.formData.subtitle4 = item.subtitle;
      } else if (item.magazineNo == 5) {
        this.formData.magazine5 = item.attachmentURL;
        this.formData.title5 = item.title;
        this.formData.subtitle5 = item.subtitle;
      }
    });
  },
  computed: {
    items() {
      return this.$store.state.magazineObjects;
    }
  },
  methods: {
    deleteDropFile(index, file) {
      if (file == 1) {
        this.magazine1File.splice(index, 1);
        this.updatemagazine1 = undefined;
      }
      if (file == 2) {
        this.magazine2File.splice(index, 1);
        this.updatemagazine2 = undefined;
      }
      if (file == 3) {
        this.magazine3File.splice(index, 1);
        this.updatemagazine3 = undefined;
      }
      if (file == 4) {
        this.magazine4File.splice(index, 1);
        this.updatemagazine4 = undefined;
      }
      if (file == 5) {
        this.magazine5File.splice(index, 1);
        this.updatemagazine5 = undefined;
      }
    },

    updateResource(magazine, val) {
      if (magazine == 1) this.updatemagazine1 = val;
      if (magazine == 2) this.updatemagazine2 = val;
      if (magazine == 3) this.updatemagazine3 = val;
      if (magazine == 4) this.updatemagazine4 = val;
      if (magazine == 5) this.updatemagazine5 = val;
    },

    async submitForm() {
      this.isSubmitting = true;
      const request = cloneDeep(this.formData);
      const uploadRequest = new FormData();
      if (this.magazine1File[0])
        uploadRequest.append("magazine1", this.magazine1File[0]);
      uploadRequest.append("title1", request.title1);
      uploadRequest.append("subtitle1", request.subtitle1);

      if (this.magazine2File[0])
        uploadRequest.append("magazine2", this.magazine2File[0]);
      uploadRequest.append("title2", request.title2);
      uploadRequest.append("subtitle2", request.subtitle2);

      if (this.magazine3File[0])
        uploadRequest.append("magazine3", this.magazine3File[0]);
      uploadRequest.append("title3", request.title3);
      uploadRequest.append("subtitle3", request.subtitle3);

      if (this.magazine4File[0])
        uploadRequest.append("magazine4", this.magazine4File[0]);
      uploadRequest.append("title4", request.title4);
      uploadRequest.append("subtitle4", request.subtitle4);

      if (this.magazine5File[0])
        uploadRequest.append("magazine5", this.magazine5File[0]);
      uploadRequest.append("title5", request.title5);
      uploadRequest.append("subtitle5", request.subtitle5);

      await this.$store.dispatch("updateMagazine", uploadRequest);
      this.$router.push({ name: "Dashboard" });

      this.isSubmitting = false;
    }
  }
};
</script>

<style lang="scss">
@import "@/assets/styles/main.scss";
</style>
